import { protectedRequest } from '@/boot/axios'

export default class BaseService {
  constructor(ressource) {
    this._apiUrl = `/${ressource}`
  }

  getById(id) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/${id}`,
    })
  }

  getByIdWithSiblings(
    id,
    { data = undefined, params = undefined } = {},
    method = 'GET',
  ) {
    if (data) method = 'POST'

    return protectedRequest({
      method,
      url: `${this._apiUrl}/${id}/with-siblings`,
      params,
      data,
    })
  }

  getAll() {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl,
    })
  }

  create(data) {
    return protectedRequest({
      method: 'POST',
      url: this._apiUrl,
      data,
    })
  }

  update(id, data) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/${id}`,
      data,
    })
  }

  delete(id) {
    return protectedRequest({
      method: 'DELETE',
      url: `${this._apiUrl}/${id}`,
    })
  }

  search({ filters, ...query }, method = 'POST') {
    return protectedRequest({
      method: method,
      url: `${this._apiUrl}/search`,
      data: filters,
      params: query,
    })
  }

  paginatedSearch({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/query`,
      data: filters,
      params: query,
    })
  }

  export(companyId = null) {
    return protectedRequest({
      timeout: 300000,
      method: 'GET',
      url: `${this._apiUrl}/export`,
      responseType: 'blob',
      params: {
        companyId,
      },
    })
  }

  import(formData) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/import`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
  }

  syncUsingApi(softwareId, company) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/import/api/${softwareId}`,
      data: {
        company,
      },
    })
  }

  duplicate(id) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/${id}/duplicate`,
    })
  }

  massUpdate(id, data, extraData, { massEditId }) {
    return protectedRequest({
      method: 'PATCH',
      url: `${this._apiUrl}/${id}/mass-update`,
      data: {
        data,
        extraData,
      },
      params: { massEditId },
    })
  }

  exportCsv(filters) {
    return protectedRequest({
      timeout: 300000,
      method: 'POST',
      url: `${this._apiUrl}/export-csv`,
      data: filters,
      responseType: 'blob',
    })
  }
}
