import LocalProductService from '../../../service/LocalProductService'
import * as mutationTypes from '@/store/modules/simpleProject/types'

export const getAll = async ({ commit }) => {
  const { data } = await LocalProductService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await LocalProductService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await LocalProductService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await LocalProductService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await LocalProductService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const importCsv = async (context, formData) => {
  const { data } = await LocalProductService.import(formData)

  return data
}

export const syncUsingApi = async (context, { softwareId, company }) => {
  const { data } = await LocalProductService.syncUsingApi(softwareId, company)

  return data
}

export const exportCsv = async ({ commit, rootState }, { filters }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await LocalProductService.exportCsv(filters)
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}
