import BudgetService from '../../../service/BudgetService'

export const addItem = async ({ commit }, item) => {
  const { data } = await BudgetService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const getAll = async ({ commit }) => {
  const { data } = await BudgetService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await BudgetService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await BudgetService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await BudgetService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const getItemById = async ({ commit }, id) => {
  const { data } = await BudgetService.getById(id)
  commit('SET_CURRENT', data)

  return data
}

export const setCurrent = ({ commit }, payload) => {
  commit('SET_CURRENT', payload)
}

export const setSelectedBudget = ({ commit }, budget) => {
  commit('SET_SELECTED_BUDGET', budget)
}
