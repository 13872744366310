import { exportCsv } from '@/store/modules/localClient/types'

export default {
  SET_LIST(state, { items, count }) {
    state.list = [...items]
    state.totalRecords = count
  },

  ADD_TO_LIST(state, item) {
    state.list = [item, ...state.list]
    state.totalRecords = state.totalRecords + 1
  },

  UPDATE_LIST(state, item) {
    const updateIndex = state.list.findIndex((el) => el._id === item._id)
    state.list[updateIndex] = item
    state.list = [...state.list]
  },

  REMOVE_FROM_LIST(state, id) {
    state.list = state.list.filter((el) => el._id !== id)
  },

  SET_CURRENT(state, payload) {
    state.current = payload
  },

  [exportCsv.requesting](state) {
    state.isExporting = true
  },
  [exportCsv.success](state, { rootState }) {
    rootState.message = { text: 'Exported successfully', type: 'error' }
    state.isExporting = false
  },
  [exportCsv.failure](state, { rootState, payload }) {
    rootState.message = { text: payload.message, type: 'error' }
    state.isExporting = false
  },
  [exportCsv.requesting](state) {
    state.isExporting = true
  },
}
