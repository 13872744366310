import ProductService from '../../../service/ProductService'
import LocalProductService from '../../../service/LocalProductService'

export const getAll = async ({ commit }) => {
  const { data } = await ProductService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ProductService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await ProductService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  let data

  if (payload.localProductFavorite) {
    ;({ data } = await LocalProductService.updateLocalProductFavorite(
      _id,
      payload,
    ))
  } else {
    ;({ data } = await ProductService.update(_id, payload))
  }
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await ProductService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}
