import CurrencyService from '../../../service/CurrencyService'

export const getAll = async ({ commit, getters }) => {
  if (getters.getAll.length) {
    return getters.getAll
  }

  const { data } = await CurrencyService.getAll()
  commit('setAll', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await CurrencyService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await CurrencyService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await CurrencyService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await CurrencyService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}
