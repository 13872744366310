import RoleService from '../../../service/RoleService'

export const getAll = async ({ commit }) => {
  const { data } = await RoleService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await RoleService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await RoleService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await RoleService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await RoleService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const updateAcl = async ({ commit }, { id, permissions }) => {
  const { data } = await RoleService.updateAcl(id, permissions)
  commit('UPDATE_LIST', data)

  return data
}
