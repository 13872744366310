import ClientService from '../../../service/ClientService'
import * as mutationTypes from '@/store/modules/contact/types'

export const getAll = async ({ commit }) => {
  const { data } = await ClientService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  if (
    query.filters['pivot._id'].value !== null &&
    query.filters['pivot._id'].value[0] === 'not_specified'
  ) {
    query.filters.type = { value: 'Master', matchMode: 'in' }
  }

  if (
    query.filters['group_client._id'].value !== null &&
    query.filters['group_client._id'].value[0] === 'not_specified'
  ) {
    query.filters.type = { value: 'Master', matchMode: 'in' }
  }

  if (
    query.filters['sector._id'].value !== null &&
    query.filters['sector._id'].value[0] === 'not_specified'
  ) {
    query.filters.type = { value: 'Master', matchMode: 'in' }
  }

  const { data } = await ClientService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await ClientService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await ClientService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await ClientService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const search = async (context, query) => {
  const { data } = await ClientService.search(query)

  return data.items
}

export const getByType = async (context, type) => {
  const { data } = await ClientService.getByType(type)

  return data.items
}

export const importCsv = async (context, formData) => {
  const { data } = await ClientService.import(formData)

  return data
}

export const exportCsv = async ({ commit, rootState }, { companyId }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await ClientService.export(companyId)
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}
