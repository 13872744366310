import TagService from '../../../service/TagService'
import * as mutationTypes from './types'

export const searchTag = async ({ commit }, { types, query, company }) => {
  try {
    commit(mutationTypes.searchTag.requesting)
    const { data } = await TagService.searchTag(types, query, company)
    commit(mutationTypes.searchTag.success, data)

    return data.items
  } catch (e) {
    commit(mutationTypes.searchTag.failure, e)
    throw e
  }
}

export const clearMessage = ({ commit }) => {
  commit(mutationTypes.clearMessage)
}

export const clearList = ({ commit }) => {
  commit(mutationTypes.clearList)
}

export const create = async ({ commit }, payload) => {
  try {
    commit(mutationTypes.addTag.requesting)
    const { data } = await TagService.create(payload)
    commit(mutationTypes.addTag.success)

    return data
  } catch (e) {
    commit(mutationTypes.addTag.failure, e)
    throw e
  }
}

export const addItem = async ({ commit }, item) => {
  const { data } = await TagService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const getAll = async ({ commit }) => {
  const { data } = await TagService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await TagService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await TagService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await TagService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}
