import { exportExcel } from './types'

export default {
  SET_LIST(state, { items, count }) {
    state.list = [...items]
    state.totalRecords = count
  },

  ADD_TO_LIST(state, item) {
    state.list = [item, ...state.list]
    state.totalRecords = state.totalRecords + 1
  },

  UPDATE_LIST(state, item) {
    const updateIndex = state.list.findIndex((el) => el._id === item._id)
    state.list[updateIndex] = item
    state.list = [...state.list]
  },

  REMOVE_FROM_LIST(state, id) {
    state.list = state.list.filter((el) => el._id !== id)
  },

  [exportExcel.requesting](state) {
    state.isExporting = true
  },

  [exportExcel.success](state, { rootState }) {
    rootState.message = { text: 'Exported successfully', type: 'error' }
    state.isExporting = false
  },

  [exportExcel.failure](state, { rootState, payload }) {
    rootState.message = { text: payload.message, type: 'error' }
    state.isExporting = false
  },

  DATA_COUNTED_PER_STATE(state, items) {
    state.dataCountedPerState = [...items]
  },
  EXPORT_CSV_REQUESTING(state) {
    state.isExporting = true
  },

  EXPORT_CSV_FAILURE(state, { rootState, payload }) {
    rootState.message = { text: payload.message, type: 'error' }
    state.isExporting = false
  },

  EXPORT_CSV_SUCCESS(state, { rootState }) {
    rootState.message = { text: 'Exported successfully', type: 'error' }
    state.isExporting = false
  },
}
