export const getList = (state) => state.list

export const getListWithMasterName = (state) =>
  state.list?.map((localClient) => {
    const name = String(localClient.display_name ?? localClient.name)

    return {
      ...localClient,
      name: localClient?.master_client?.name
        ? name.concat(` (${localClient.master_client.name})`)
        : name,
    }
  })

export const getTotalRecords = (state) => state.totalRecords

export const getCurrent = (state) => state.current

export const isExporting = (state) => state.isExporting
