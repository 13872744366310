const visible = (ressource, scope = '') => {
  if (scope) {
    return scope
  }

  return (can) => {
    return can('read', ressource)
  }
}

export const MENU = [
  {
    label: 'Management',
    items: [
      {
        label: 'Group Management',
        items: [
          {
            label: 'Company',
            icon: 'pi pi-fw pi-building',
            name: 'company_management',
            visible: visible('Company'),
          },
          {
            label: 'Currency',
            icon: 'pi pi-fw pi-money-bill',
            name: 'currency_management',
            visible: visible('Currency'),
          },
          {
            label: 'Reference Exch. Rates',
            icon: 'pi pi-fw pi-euro',
            name: 'reference_exchange_rates_management',
            visible: visible('ReferenceExchangeRate'),
          },
          {
            label: 'Group/Master Client',
            icon: 'pi pi-fw pi-comments',
            name: 'client_management',
            visible: visible('Client'),
          },
          {
            label: 'Local Product Type',
            icon: 'pi pi-fw pi-tag',
            name: 'local_product_type_management',
            visible: visible('LocalProductType'),
          },
          {
            label: 'Local Software',
            icon: 'pi pi-fw pi-link',
            name: 'entity_billing_software_management',
            visible: visible('EntityBillingSoftware'),
          },
          {
            label: 'Master Product',
            icon: 'pi pi-fw pi-shopping-cart',
            name: 'product_management',
            visible: visible('Product'),
          },
          {
            label: 'Role',
            icon: 'pi pi-fw pi-users',
            name: 'role_management',
            visible: visible('Role'),
          },
          {
            label: 'Sector',
            icon: 'pi pi-fw pi-sitemap',
            name: 'sector_management',
            visible: visible('Sector'),
          },
          {
            label: 'User',
            icon: 'pi pi-fw pi-user',
            name: 'user_management',
            visible: visible('User'),
          },
          {
            label: 'Interco',
            icon: 'pi pi-fw pi-chart-pie',
            name: 'interco',
            visible: visible('User'),
          },
          {
            label: 'Jedox Reporting Line',
            icon: 'pi pi-fw pi-book',
            name: 'jedox_reporting_line',
            visible: visible('JedoxReportingLine'),
          },
        ],
      },
      {
        label: 'Company Management',
        visible: visible(null, 'COMPANY_SCOPED'),
        items: [
          {
            label: 'Contact',
            icon: 'pi pi-fw pi-id-card',
            name: 'contact_management_listing',
            visible: visible('Contact'),
          },
          {
            label: 'Contract',
            icon: 'pi pi-fw pi-file',
            name: 'contract_management',
            visible: visible('Contract'),
          },
          {
            label: 'Local Client',
            icon: 'pi pi-fw pi-comments',
            name: 'local_client_management_listing',
            visible: visible('LocalClient'),
          },
          {
            label: 'Local Product',
            icon: 'pi pi-fw pi-tag',
            name: 'local_product_management',
            visible: visible('LocalProduct'),
          },
          {
            label: 'My Declination of Master Products',
            icon: 'pi pi-fw pi-star',
            name: 'declination_master_product',
            visible: visible('DeclinationMasterProduct'),
          },
        ],
      },
      {
        label: 'Projects',
        items: [
          {
            label: 'Program Management',
            icon: 'pi pi-fw pi-briefcase',
            name: 'program_management_listing',
            visible: visible('ProgramManagement'),
          },
          {
            label: 'Simple Project',
            icon: 'pi pi-fw pi-briefcase',
            name: 'simple_projects_listing',
            visible: visible('Project'),
          },
          {
            label: 'Internal Project',
            icon: 'pi pi-fw pi-briefcase',
            name: 'internal_projects_listing',
            visible: visible('Project'),
          },
          {
            label: 'Services',
            icon: 'pi pi-fw pi-file-o',
            name: 'services',
            visible: visible('Services'),
          },
        ],
      },
      {
        label: 'Billing Process',
        items: [
          {
            label: 'Production',
            icon: 'pi pi-fw pi-file-o',
            name: 'production',
            visible: visible('Production'),
          },
          {
            label: 'External Production',
            icon: 'pi pi-fw pi-file-o',
            name: 'externalproduction',
            visible: visible('ExternalProduction'),
          },
          {
            label: 'Post Production',
            icon: 'pi pi-fw pi-file-o',
            name: 'postproduction',
            visible: visible('Postproduction'),
          },
          {
            label: 'External Post Production',
            icon: 'pi pi-fw pi-file-o',
            name: 'external_post_production',
            visible: visible('Postproduction'),
          },
          {
            label: 'PreBilling',
            icon: 'pi pi-fw pi-file-o',
            name: 'prebilling',
            visible: visible('Prebilling'),
          },
        ],
      },
      {
        label: 'Costs',
        items: [
          {
            label: 'Internal Costs',
            icon: 'pi pi-fw pi-shopping-cart',
            name: 'internalCost',
            visible: visible('InternalCost'),
          },
        ],
      },
      {
        label: 'Sales Analytics',
        items: [
          {
            label: 'Sales',
            icon: 'pi pi-fw pi-dollar',
            name: 'sales',
            visible: visible('Sales'),
          },
          {
            label: 'Sales pivot table',
            icon: 'pi pi-fw pi-dollar',
            name: 'real',
            visible: visible('Real'),
          },
        ],
      },
      {
        label: 'Budget',
        items: [
          {
            label: 'All Budgets',
            icon: 'pi pi-fw pi-list',
            name: 'budgets',
            visible: visible('Budget'),
          },
          {
            label: 'Budget Entries',
            icon: 'pi pi-fw pi-wallet',
            name: 'budget_entries',
            visible: visible('Budget'),
          },
          {
            label: 'Budget dashboard',
            icon: 'pi pi-fw pi-server',
            name: 'budget_dashboard',
            //visible: visible('BudgetDashboard'),
          },
        ],
      },
      {
        label: 'API Connector',
        items: [
          {
            label: 'Quickbook',
            icon: 'pi pi-fw pi-link',
            name: 'quickbook_management',
            visible: visible('Quickbooks'),
          },
        ],
      },
      {
        label: 'Happy Store',
        items: [
          {
            label: 'Platform',
            icon: 'pi pi-fw pi-sitemap',
            name: 'platform_management',
            visible: visible('Platform'),
          },
          {
            label: 'Tags',
            icon: 'pi pi-fw pi-tags',
            name: 'tag_listing',
            visible: visible('Tag'),
          },
          {
            label: 'Operating Zone',
            icon: 'pi pi-fw pi-map-marker',
            name: 'operatingZone_listing',
            visible: visible('OperatingZone'),
          },
          {
            label: 'Client Provider',
            icon: 'pi pi-fw pi-bookmark',
            name: 'clientProvider_listing',
            visible: visible('ClientProvider'),
          },
          {
            label: 'Templates',
            icon: 'pi pi-fw pi-folder-open',
            name: 'templates',
            visible: visible('Template'),
          },
          {
            label: 'Import Template',
            icon: 'pi pi-fw pi-folder-open',
            name: 'import_template',
            visible: visible('ImportTemplate'),
          },
          {
            label: 'Client vs Advertiser',
            icon: 'pi pi-fw pi-users',
            name: 'client_advertiser',
            visible: visible('ClientAdvertiser'),
          },
          {
            label: 'Features',
            icon: 'pi pi-fw pi-flag',
            name: 'features_management',
            visible: visible('Feature'),
          },
        ],
      },
      {
        label: 'Audit',
        items: [
          {
            label: 'Logging',
            icon: 'pi pi-fw pi-pencil',
            name: 'logging',
            visible: visible('Logging'),
          },
        ],
      },
      {
        label: 'Security',
        items: [
          {
            label: 'API Tokens',
            icon: 'pi pi-fw pi-lock',
            name: 'api_tokens_management',
            visible: visible('ApiToken'),
          },
        ],
      },
    ],
  },
]
