import {
  addTag,
  addToList,
  clearList,
  clearMessage,
  removeFromList,
  searchTag,
  setList,
  updateList,
} from './types'

export default {
  [clearMessage](state) {
    state.message = null
  },
  [searchTag.requesting](state) {
    state.isSearchingTag = true
  },
  [searchTag.success](state, payload) {
    state.list = payload.items
    state.isSearchingTag = false
  },
  [searchTag.failure](state, error) {
    state.message = { text: error.message, type: 'error' }
    state.isSearchingTag = false
  },
  [addTag.requesting](state) {
    state.isAddingTag = true
  },
  [addTag.success](state) {
    state.message = { text: 'Tag added successfully !', type: 'success' }
    state.isAddingTag = false
  },
  [addTag.failure](state, error) {
    state.message = { text: error.message, type: 'error' }
    state.isAddingTag = false
  },
  [clearList](state) {
    state.list = []
  },
  [setList](state, { items, count }) {
    state.list = [...items]
    state.totalRecords = count
  },
  [addToList](state, item) {
    state.list = [item, ...state.list]
    state.totalRecords = state.totalRecords + 1
  },
  [updateList](state, item) {
    const updateIndex = state.list.findIndex((el) => el._id === item._id)
    state.list[updateIndex] = item
    state.list = [...state.list]
  },
  [removeFromList](state, id) {
    state.list = state.list.filter((el) => el._id !== id)
  },
}
