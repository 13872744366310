import OperatingZoneService from '../../../service/OperatingZoneService'

export const addItem = async ({ commit }, item) => {
  const { data } = await OperatingZoneService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const getAll = async ({ commit }) => {
  const { data } = await OperatingZoneService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await OperatingZoneService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await OperatingZoneService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await OperatingZoneService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}
