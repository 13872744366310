import { helpers } from '@vuelidate/validators'

export const enumValidator = (enums) => {
  return function (value) {
    return enums.indexOf(value) !== -1
  }
}

export const customUrlRule = (value) => {
  return (
    !helpers.req(value) ||
    value.startsWith('http://') ||
    value.startsWith('https://')
  )
}

export const booleanValidator = (value) =>
  !helpers.req(value) || typeof value === 'boolean'
