import SimpleProjectService from '../../../service/ProjectService'
import * as mutationTypes from './types'

export const getAll = async ({ commit }) => {
  const { data } = await SimpleProjectService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await SimpleProjectService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await SimpleProjectService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const getItemById = async (contect, id) => {
  const { data } = await SimpleProjectService.getById(id)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await SimpleProjectService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await SimpleProjectService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const duplicateItem = async ({ commit }, id) => {
  const { data } = await SimpleProjectService.duplicate(id)
  commit('ADD_TO_LIST', data)

  return data
}

export const submitServicesToAdminBillers = async (
  context,
  { id, payload },
) => {
  const { data } = await SimpleProjectService.submitServicesToAdminBillers(
    id,
    payload,
  )

  return data
}

export const exportExcelForEditQuote = async ({ commit, rootState }, query) => {
  try {
    commit(mutationTypes.exportExcel.requesting)
    const res = await SimpleProjectService.exportExcelForEditQuote(query)
    commit(mutationTypes.exportExcel.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportExcel.failure, { payload: e, rootState })
  }
}

export const countPerState = async ({ commit }, payload) => {
  const { data } = await SimpleProjectService.countPerState(payload)
  commit('DATA_COUNTED_PER_STATE', data)

  return data
}

export const exportCsv = async ({ commit, rootState }, { filters }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await SimpleProjectService.exportCsv(filters)
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}
