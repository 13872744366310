import { protectedRequest } from '../boot/axios'
import BaseService from './BaseService'

class UserService extends BaseService {
  constructor() {
    super('users')
  }

  // other implementation goes here
  getUserByCompany(companies = [], userId = null) {
    const params = {
      q: companies.join(','),
    }

    if (userId) {
      params.user_id = userId
    }

    return protectedRequest({
      method: 'GET',
      url: this._apiUrl + '/company',
      params,
    })
    // return this._axios.get(`${this._apiUrl}/company`, { params });
  }

  searchUser(query = '') {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl + '/search',
      params: { q: query },
    })
    // return this._axios.get(`${this._apiUrl}/search`, { params: { q: query } });
  }

  getMe() {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl + '/me',
    })
  }

  changeCompany(company) {
    if (!company) {
      throw Error('Invalid company ID !')
    }

    return protectedRequest({
      method: 'POST',
      url: this._apiUrl + '/selectedCompany',
      data: {
        company: company,
      },
    })
  }

  hasUnseenFeature() {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/has-unseen-feature`,
    })
  }

  updateSeenFeatures(featureId) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/seen-features`,
      data: {
        featureId,
      },
    })
  }
}

export default new UserService()
