const {
  networkMutationsCreator,
} = require('../../../common/utils/creator.utils')

export const searchTag = networkMutationsCreator('SEARCH_TAG')

export const clearList = 'CLEAR_LIST'

export const addTag = networkMutationsCreator('ADD_TAG')

export const clearMessage = 'CLEAR_MESSAGE'

export const setList = 'SET_LIST'

export const addToList = 'ADD_TO_LIST'

export const updateList = 'UPDATE_LIST'

export const removeFromList = 'REMOVE_FROM_LIST'
